import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Seo from "../components/layout/seo";
import Frozen from "../components/pages/frozen";

const data = {
  intro:
    "Il Trattamento Frozen è un rivestimento performante ad alta tecnologia che viene applicato sulle lame per uno spessore di circa 2 micron, compresa la parte tagliente, per la Altezza di taglio prevista. Questo tipo di rivestimento protegge il corpo della lama e durante la fase di taglio evita che si surriscaldi e quindi subisca delle deformazioni; infatti su questi utensili non sono previsti i denti stabilizzatori.",
  blocks: [
    {
      type: "paragraph",
      title: "Rivestimento performante",
      text: (
        <p>
          L’elevato grado antiaderente di questo rivestimento favorisce al
          massimo Io scarico del truciolo e impedisce l’accumulo della resina
          soprattutto in prossimità delle placchette (inconveniente comune a
          tutti gli utensili che vengono impiegati per queste Iavorazioni).
          <br />
          L’utensile rimane costantemente pulito e non viene richiesto alcun
          intervento per la manutenzione se non nel momento in cui è necessaria
          la riaffilatura delle placchette. Con questo tipo di rivestimento si
          riducono notevolmente le sollecitazioni a cui viene sottoposto
          l’utensile durante la fase di taglio, determinando quindi una vita più
          lunga della lama ed una maggiore durata di taglio.
          <br />
          Le condizioni ottimali d’impiego consentono quindi di sfruttare al
          massimo le potenzialità di questo utensile, infatti si può arrivare ad
          una durata di taglio maggiore anche di quattro volte rispetto ad un
          utensile convenzionale, considerando anche il fatto che si può
          sfruttare la placchetta fino al suo esaurimento. L’utilizzo di questi
          utensili riduce notevolmente I’assorbimento di potenza dei motore
          della macchina.
        </p>
      ),
    },
    {
      type: "comparison",
      title: "Prova di sfregamento",
      text: (
        <p>
          Temperatura più bassa di circa 1/3 grazie al nostro trattamento, e
          solo lievi tracce di annerimento dopo un prolungato impiego.
          <br />
          <br />
          Frozen abbassa la temperatura della lama di circa 1/3, presentando sul
          corpo solo lievi tracce di annerimento dopo un impiego prolungato.
          <br />
          <br />A pari condizioni, alcune zone del corpo della lama senza
          rivestimento si surriscaldano in breve tempo, a causa della minore
          resistenza al calore.
        </p>
      ),
      imgIs: (
        <StaticImage
          formats={["web", "auto"]}
          src="../resources/images/specifiche/sfreg-is.png"
          layout="fullWidth"
          alt="effetto anti-sfregamento lame international saws"
        />
      ),
      imgComp: (
        <StaticImage
          formats={["web", "auto"]}
          src="../resources/images/specifiche/sfreg-comp.png"
          layout="fullWidth"
          alt="effetto sfregamento sulle lame dei competitor"
        />
      ),
    },
    {
      type: "comparison",
      title: "Niente più ruggine",
      text: (
        <p>
          Grazie al trattamento Frozen, le lame International Saws non
          arrugginiscono, avendo una particolare resistenza all'umidità
          superiore di 5 volte rispetto alla norma.
          <br />
          <br />
          Lo speciale trattamento fa si che la lama resista notevolmente
          all’umidità e quindi dalla corrosione (ruggine) di circa 5 volte
          rispetto allo standard.
          <br />
          <br />
          Una lama senza rivestimento teme fortemente l’umidità, specialmente in
          prossimità dei denti e degli eventuali intagli presenti sul corpo.
        </p>
      ),
      imgIs: (
        <StaticImage
          formats={["web", "auto"]}
          src="../resources/images/specifiche/rugg-is.png"
          layout="fullWidth"
          alt="niente ruggine sulle lame international saws"
        />
      ),
      imgComp: (
        <StaticImage
          formats={["web", "auto"]}
          src="../resources/images/specifiche/rugg-comp.png"
          layout="fullWidth"
          alt="effetto della ruggine sulle lame dei competitor"
        />
      ),
    },
    {
      type: "comparison",
      title: "Eccezionale antiaderenza",
      text: (
        <p>
          Il particolare trattamento Frozen conferisce alle lame a marchio
          International Saws eccezionali proprietà antiaderenti.
          <br />
          <br />
          Il trattamento Frozen rende la lama particolarmente adatta al taglio
          di legni resinosi.
          <br />
          <br />
          Una lama senza rivestimento è ricettiva al deposito delle resine
          rilasciate da alcuni tipi di legname.
        </p>
      ),
      imgIs: (
        <StaticImage
          formats={["web", "auto"]}
          src="../resources/images/specifiche/antiad-is.png"
          layout="fullWidth"
          alt="proprietà anti-aderenti delle lame international saws"
        />
      ),
      imgComp: (
        <StaticImage
          formats={["web", "auto"]}
          src="../resources/images/specifiche/antiad-comp.png"
          layout="fullWidth"
          alt="le lame dei competitor non sono anti-aderenti"
        />
      ),
    },
  ],
};

const TrattamentoFrozen = ({ location }) => (
  <>
    <Seo
      title="Trattamento Frozen"
      desc="Il Trattamento Frozen è un rivestimento performante ad alta tecnologia che viene applicato per proteggere il corpo della lama durante la fase di taglio."
    />
    <Frozen data={data} location={location} />
  </>
);

export default TrattamentoFrozen;
