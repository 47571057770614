import React from "react";

const ParagraphBlok = ({ b }) => (
  <div className="md:mt-16">
    <h2 className="is-h3b">{b.title}</h2>
    <p className="mt-4">{b.text}</p>
  </div>
);

export default ParagraphBlok;
