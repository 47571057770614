import React from "react";

const ComparisonBlok = ({ b }) => (
  <div className="mt-16 flex flex-wrap gap-12">
    <div className="md:flex-1 w-full md:w-auto">
      <h2 className="is-h3b">{b.title}</h2>
      <p className="mt-4">{b.text}</p>
    </div>
    <div className="md:flex-1 w-full md:w-auto">
      <p className="is-h3b mb-4">International Saws</p>
      {b.imgIs}
    </div>
    <div className="md:flex-1 w-full md:w-auto">
      <p className="is-h3b mb-4">Competitors</p>
      {b.imgComp}
    </div>
  </div>
);

export default ComparisonBlok;
